//
// footer.scss
// Theme component
//

// Brand image

.bg-dark .footer-brand {
  filter: brightness(0) invert(1);
  width: 200px;
}

.equal-housing {
  width: 60px;
}

.footer-requirements {
  margin-top: 20px;
}
